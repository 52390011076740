import { Component, Input, OnInit } from "@angular/core";
import { AlertaService } from "./alerta.service";
import { NivelAlerta, TiposAlerta } from "../../utils/Alertas";

@Component({
    selector: 'alertaCustom',
    templateUrl: './alerta.component.html'
})
export class AlertaComponent implements OnInit {
        

    constructor(public alertService:AlertaService) { }

    ngOnInit() {    
        
    }

    getClassOf(val:NivelAlerta) {
        switch(val) {
            case 1:
                return "alert-success";
            case 2:
                return "alert-warning";
            case 3:
                return "alert-info";
            case 4:
                return "alert-danger";
            default:
                return "alert-info";    

        }
    }



}
  