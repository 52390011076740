import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertaComponent } from './alertas/alerta.component';
import { PaginadoComponent } from './paginado/paginado.component';



@NgModule({
  declarations: [
    AlertaComponent,
    PaginadoComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AlertaComponent,
    PaginadoComponent
  ]
})
export class SharedModule { }
