import { Injectable } from "@angular/core";
import { NivelAlerta, TiposAlerta } from "../../utils/Alertas";
import { ErrorHttpService } from "../../utils/errorHttpManager/errorHttp.service";

@Injectable({
    providedIn: 'root'
})
export class AlertaService {

    nivelAlerta:NivelAlerta = null;
    mensaje:String = null;

    defaultCallBack = () => {
        setTimeout(() => {
          this.nivelAlerta = null;
        }, 2000);
      }

    constructor(private errorHttp:ErrorHttpService) {

    }

    send(type:TiposAlerta,
         nivelAlerta: NivelAlerta,
         mensaje?:String,
         errorCode?:Number,
         callback?:Function 
    ){
        if (type === TiposAlerta.HTTP){
            this.mensaje = this.errorHttp.getMessageByCode(errorCode);
        } else {
            this.mensaje = mensaje;
        }

        this.nivelAlerta = nivelAlerta;

        if (callback){
            callback.apply(null);
        } else {
            this.defaultCallBack.apply(null);
        }

    }

} 