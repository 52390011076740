import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PaginadoService {

    private tamPag = new BehaviorSubject<number>(0);
    changeTam = this.tamPag.asObservable();

    paginasTotalSave:number;

    constructor(private http:HttpClient) {

    }

    actualizarTamPaginado(paginasTotal: number) {
        if(paginasTotal !== this.paginasTotalSave) {
            this.paginasTotalSave = paginasTotal;
            this.tamPag.next(paginasTotal);
        }        
    }
   

} 