import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NivelAlerta } from "../../utils/Alertas";
import { Paginado } from "./paginado";
import { PaginadoService } from "./paginado.service";

@Component({
    selector: 'paginado',
    templateUrl: './paginado.component.html'
})
export class PaginadoComponent implements OnInit {
        
    
    _paginado:Paginado 

    arrayPaginas:number[];
    
    get paginado(): Paginado {
        return this._paginado;
    }

    @Input() set paginado(paginado:Paginado){
        this._paginado=paginado;   
        this.paginadoService.changeTam.subscribe((paginasTotal:number)=>{
            this.crearArrayHasta(paginasTotal);
        })
        
    }
    
    @Output()
    pageClicked = new EventEmitter<number>();

    constructor(private paginadoService:PaginadoService) { }

    ngOnInit() {    
        console.log("El paginado actual",this._paginado)
    }


    crearArrayHasta(numero: number):void {
        const array: number[] = [];
        for (let i = 1; i <= numero; i++) {
            array.push(i);
        }
        this.arrayPaginas =  array;
    }
   
    cambiarPagina(numero:number) {
        this._paginado.paginaActual = numero;
        this.pageClicked.emit(numero);
    }



}
  