import { Injectable } from "@angular/core";
import * as errorCodes from '../../../assets/error.json';

@Injectable({
    providedIn: 'root'
  })
export class ErrorHttpService {
    
    errorCodes = {};

    constructor() {
        this.errorCodes = errorCodes;        
    }

    getMessageByCode(code:any): String {
        return this.errorCodes[code] != null ? this.errorCodes[code] : this.errorCodes["default"];
    }


}