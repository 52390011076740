import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl+"/auth"
  private tokenKey = 'auth-token';

  constructor(private http: HttpClient,private router: Router) { }

  login(credentials: {nombreUsuario: string, password: string}): Observable<any> {
    return this.http.post(`${this.apiUrl}/login`, credentials)
      .pipe(
        tap((response: any) => {
          localStorage.setItem(this.tokenKey, response.token);
        })
      );
  }

  logout(): void {
    localStorage.removeItem(this.tokenKey);
    this.router.navigate(['/']);
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  isNotAuthenticated(): boolean {
    return !this.isAuthenticated;
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    // Check if token is present and not expired
    return !!token;
  }
}
